import "es6-promise/auto";
import lazysizes from 'lazysizes';
import Swiper from 'swiper';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// App main
const main = async () => {
    // Import our CSS
    const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
    // Create our vue instance
    const vm = new Vue.default({
        el: "#site",
        delimiters: ['${', '}'],
        components: {
            'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
            'formulier': () => import(/* webpackChunkName: "formulier" */ '../vue/Formulier.vue'),
            'modal': () => import(/* webpackChunkName: "modal" */ '../vue/Modal.vue'),
            'spermaModal': () => import(/* webpackChunkName: "spermaModal" */ '../vue/SpermaModal.vue'),
            'requestPrice': () => import(/* webpackChunkName: "requestPrice" */ '../vue/RequestPrice.vue'),
            'subscribe': () => import(/* webpackChunkName: "subscribe" */ '../vue/Subscribe.vue'),
            'spermaFormulier': () => import(/* webpackChunkName: "spermaFormulier" */ '../vue/SpermaFormulier.vue'),
            'stallions': () => import(/* webpackChunkName: "stallions" */ '../vue/Stallions.vue'),
            'horses': () => import(/* webpackChunkName: "horses" */ '../vue/Horses.vue'),
            'nieuws': () => import(/* webpackChunkName: "nieuws" */ '../vue/Nieuws.vue'),
        },
        data: {
            'mobileClass': false,
            'showModal': false,
            'showModal1': false,
            'showModal2': false,
            'showModal3': false,
            'showModal4': false,
            'spermaModal': false,
            'spermaModal2': false,
            'requestPriceModal2': false,
        },
        methods: {
            openMobileNav: function () {
                this.mobileClass = !this.mobileClass;
            }
        },

        mounted() {
            require('./javascript');
            require('./swipers');
            require('./parallax');
        }
    });
};
// Execute async function
main().then( (value) => {

});
