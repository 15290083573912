/**
 * Standaard boilerplate scripts
 */

var addListener = (element, type, callback) => {
    if (element.addEventListener) {
        element.addEventListener(type, callback);
    } else if (element.attachEvent) {
        element.attachEvent('on' + type, callback);
    }
};

/*/ cookiepage settings /*/
function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
}

(function() {
    var buttonAccept = document.getElementsByClassName('cookiesettings__card--accept')[0],
        buttonDecline = document.getElementsByClassName('cookiesettings__card--decline')[0];

    if (buttonAccept) {
        buttonAccept.onclick = function () {
            createCookie('cookieSet', true, 10000);
            location.reload();
        };

        buttonDecline.onclick = function () {
            createCookie('cookieSet', 'declined', 10000);
            location.reload();
        };
    }
})();

// menu
var menuOnLoad = () => {
    var ulHasChildren = document.getElementsByClassName('menu__list');
    for (var i = 0; i < ulHasChildren.length; i++) {
        if (ulHasChildren[i].childNodes.length === 2) {
            ulHasChildren[i].classList.add('has-children');
        }
    }

    var menuChildToggle = document.getElementsByClassName('has-children');
    for (let k = 0; k < menuChildToggle.length; k++) {
        menuChildToggle[k].addEventListener('click', function () {
            var menu = menuChildToggle[k].childNodes;
            if (menuChildToggle[k].classList) {
                menuChildToggle[k].classList.toggle('open-parent');
                menu[1].classList.toggle('open');
            }
        })
    }
};
if (window.attachEvent) {
    window.attachEvent('onload', menuOnLoad);
} else if (window.addEventListener) {
    window.addEventListener('load', menuOnLoad, false);
} else {
    document.addEventListener('load', menuOnLoad, false);
}

(() => {
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    var notificationIE = document.getElementsByClassName('notificationIE')[0];
    var exit = document.getElementsByClassName('notificationIE-exit')[0];
    if (isIE === true) {
        var exitIEFunction = () => notificationIE.parentNode.removeChild(notificationIE);
        notificationIE.classList.add('block');
        notificationIE.classList.remove('hidden');
        exit.onclick = () => exitIEFunction();
    } else {
        notificationIE.parentNode.removeChild(notificationIE);
    }
})();

(() => {
    var faqItem = document.getElementsByClassName('faq__question');
    for (let k = 0; k < faqItem.length; k++) {
        let faqSibling = faqItem[k].nextElementSibling;
        faqItem[k].addEventListener('click', () => {
            if (faqSibling.offsetHeight !== faqSibling.scrollHeight) {
                faqItem[k].parentElement.classList.add('active');
                faqSibling.style.height = faqSibling.scrollHeight + "px";
            }
            else {
                faqSibling.style.height = 0;
                faqItem[k].parentElement.classList.remove('active');
            }
        });
    }
})();

(() => {
    var showall = document.getElementsByClassName('show-all');
    for (let k = 0; k < showall.length; k++) {
        showall[k].addEventListener('click', () => {
            var parent = showall[k].parentNode;
            if (parent.classList) {
                parent.classList.toggle('active');
            }
        })
    }
})();

(() => {
    var tabLink = document.querySelectorAll("[data-tabLink]");
    var tabContent = document.querySelectorAll("[data-tabContent]");
    if(tabLink.length > 1) {
        for (let k = 0; k < tabLink.length; k++) {
            tabLink[k].addEventListener('click', () => {
                for (var l = 0; l < tabLink.length; l++) {
                    tabLink[l].classList.remove('active');
                }
                tabLink[k].classList.add('active');
                for (let i = 0; i < tabContent.length; i++) {
                    var tabLinkAttr = tabLink[k].getAttribute("data-tabLink");
                    var tabContentAttr = tabContent[i].getAttribute("data-tabContent");
                    tabContent[i].style.display = "none";
                    if (tabContentAttr === tabLinkAttr) {
                        tabContent[i].style.display = "flex";
                    }
                }
            });
        }
        document.querySelector("[data-defaultOpen]").click();
    }
})();

window.onscroll = function () {
    scrollFunction();
};
function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementsByClassName("scrollTop")[0].style.display = "flex";
    } else {
        document.getElementsByClassName("scrollTop")[0].style.display = "none";
    }
}

(() => {
    var aHref = document.querySelectorAll('a[href^="#"]');
    for (var k = 0; k < aHref.length; k++) {
        aHref[k].addEventListener('click', function(e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });

            if (document.querySelector("[data-activeborder]")) {
                document.querySelector("[data-activeborder]").classList.add('activeBorder');
                var activeItemOverlay = document.createElement('div');
                activeItemOverlay.classList.add('activeItemOverlay');
                activeItemOverlay.style.height = document.body.offsetHeight + 'px';
                document.getElementById('sidebar').style.zIndex = 99999999;
                document.body.appendChild(activeItemOverlay);

                setTimeout(function() {
                    document.querySelector("[data-activeborder]").classList.remove('activeBorder');
                    document.getElementById('sidebar').style.zIndex = 'inherit';
                    document.getElementsByClassName('activeItemOverlay')[0].remove();
                }, 2000);
            }
        });
    }
})();

if (typeof Object.assign != 'function') {
    Object.assign = function(target) {
        'use strict';
        if (target == null) {
            throw new TypeError('Cannot convert undefined or null to object');
        }

        target = Object(target);
        for (var index = 1; index < arguments.length; index++) {
            var source = arguments[index];
            if (source != null) {
                for (var key in source) {
                    if (Object.prototype.hasOwnProperty.call(source, key)) {
                        target[key] = source[key];
                    }
                }
            }
        }
        return target;
    };
}
