import Swiper from "swiper";
import Instafeed from './InstagramFeed-new.min';


var afbeeldingSlider = new Swiper('.js-afbeelding-slider', {
    slidesPerView: 'auto',
    spaceBetween: 42,
    navigation: {
        nextEl: '.jsSwiper-afbeelding-next',
        prevEl: '.jsSwiper-afbeelding-prev',
    },
    breakpoints: {
        640: {
            spaceBetween: 8,
        }
    }
});

var defaultSlider = new Swiper('.js-default-slider', {
    init: false,
    slidesPerView: 3,
    loopedSlides: 3,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    loop: true,
    navigation: {
        nextEl: '.jsSwiper-default-next',
        prevEl: '.jsSwiper-default-prev',
    },
    breakpoints: {
        640: {
            slidesPerView: 1
        }
    }
});

var recentlySlider = new Swiper('.js-recently-slider', {
    slidesPerView: 1,
    spaceBetween: 12,
    navigation: {
        nextEl: '.jsSwiper-recently-next',
        prevEl: '.jsSwiper-recently-prev',
    },
});

var highlightedSlider = new Swiper('.js-highlighted-slider', {
    slidesPerView: 1,
    spaceBetween: 12,
    navigation: {
        nextEl: '.jsSwiper-highlighted-next',
        prevEl: '.jsSwiper-highlighted-prev',
    },
});

var newsSlider = new Swiper('.js-news-slider', {
    slidesPerView: 1,
    spaceBetween: 12,
    navigation: {
        nextEl: '.jsSwiper-news-next',
        prevEl: '.jsSwiper-news-prev',
    },
});

var horseHeroSliderThumbs = new Swiper('.js-horseHeroThumbs-slider', {
    slidesPerView: 'auto',
    allowTouchMove: false,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
});

var horseHeroSlider = new Swiper('.js-horseHero-slider', {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
        nextEl: '.jsSwiper-horseHero-next',
        prevEl: '.jsSwiper-horseHero-prev',
    },
    thumbs: {
        swiper: horseHeroSliderThumbs
    }
});

if (document.getElementsByClassName('js-default-slider').length) {
    defaultSlider.on('init', function () {
        setTimeout(function(){
            let index = defaultSlider.activeIndex;
            let nextSib = defaultSlider.slides[index].nextElementSibling;
            let nextSib2 = nextSib.nextElementSibling;
            let nextSib3 = nextSib2.nextElementSibling;
            let sib1 = defaultSlider.slides[index].previousElementSibling;
            for (var i = 0; i < defaultSlider.slides.length; i++) {
                defaultSlider.slides[i].childNodes[0].style.left = '0';
                defaultSlider.slides[i].childNodes[0].style.top = '0';
            }

            defaultSlider.slides[index].style.opacity = '1';
            defaultSlider.slides[index].childNodes[0].style.left = '0';
            defaultSlider.slides[index].childNodes[0].style.top = '0';
            defaultSlider.slides[index].style.zIndex = '3';

            if (nextSib) {
                nextSib.style.opacity = '1';
                nextSib.childNodes[0].style.left = '-32px';
                nextSib.childNodes[0].style.top = '-32px';
                nextSib.style.zIndex = '2';
            }
            if (nextSib2) {
                nextSib2.style.opacity = '1';
                nextSib2.childNodes[0].style.left = '-64px';
                nextSib2.childNodes[0].style.top = '-64px';
                nextSib2.style.zIndex = '1';
            }
            if (nextSib3) {
                nextSib3.style.opacity = '0';
                nextSib3.childNodes[0].style.left = '-74px';
                nextSib3.childNodes[0].style.top = '-74px';
                nextSib3.style.zIndex = '0';
            }
            if (sib1) {
                sib1.style.opacity = '0';
                sib1.childNodes[0].style.left = '32px';
                sib1.style.zIndex = '4';
            }
        }, 600);
    });
    defaultSlider.init();
    defaultSlider.on('slideChange', function () {
        let index = defaultSlider.activeIndex;
        let nextSib = defaultSlider.slides[index].nextElementSibling;
        let nextSib2 = nextSib.nextElementSibling;
        let nextSib3 = nextSib2.nextElementSibling;
        let sib1 = defaultSlider.slides[index].previousElementSibling;
        for (var i = 0; i < defaultSlider.slides.length; i++) {
            defaultSlider.slides[i].childNodes[0].style.left = '0';
            defaultSlider.slides[i].childNodes[0].style.top = '0';
            defaultSlider.slides[i].style.zIndex = '0';
        }

        defaultSlider.slides[index].style.opacity = '1';
        defaultSlider.slides[index].childNodes[0].style.left = '0';
        defaultSlider.slides[index].childNodes[0].style.top = '0';
        defaultSlider.slides[index].style.zIndex = '3';

        if (nextSib) {
            nextSib.style.opacity = '1';
            nextSib.childNodes[0].style.left = '-32px';
            nextSib.childNodes[0].style.top = '-32px';
            nextSib.style.zIndex = '2';
        }
        if (nextSib2) {
            nextSib2.style.opacity = '1';
            nextSib2.childNodes[0].style.left = '-64px';
            nextSib2.childNodes[0].style.top = '-64px';
            nextSib2.style.zIndex = '1';
        }
        if (nextSib3) {
            nextSib3.style.opacity = '0';
            nextSib3.childNodes[0].style.left = '-74px';
            nextSib3.childNodes[0].style.top = '-74px';
            nextSib3.style.zIndex = '0';
        }
        if (sib1) {
            sib1.style.opacity = '0';
            sib1.childNodes[0].style.left = '32px';
            sib1.style.zIndex = '4';
        }
    });
}




var testimonialsSlider = new Swiper('.js-testimonials-slider', {
    init: false,
    slidesPerView: 1,
    grabCursor: true,
    spaceBetween: 138,
    navigation: {
        nextEl: '.jsSwiper-testimonials-next',
        prevEl: '.jsSwiper-testimonials-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
            var testimonialsPaginationArray = document.getElementsByClassName('testimonialsPagination');
            var dataTesti = [];
            dataTesti.push(testimonialsPaginationArray[index].getAttribute("data-testimonial"));
            return '<span class="' + className + '">' + dataTesti + '</span>';
        },
    },
});


if (document.getElementsByClassName('activateOpacity').length) {
    testimonialsSlider.on('init', function () {
            let index = testimonialsSlider.activeIndex;
            for (let i = 0; i < testimonialsSlider.slides.length; i++) {
                testimonialsSlider.slides[i].style.opacity = '0';
                testimonialsSlider.slides[index].style.opacity = '1';
            }
    });
}
if (document.getElementsByClassName('js-testimonials-slider').length) {
    testimonialsSlider.init();
}

testimonialsSlider.on('slideChange', function () {
    let index = testimonialsSlider.activeIndex;

    for (let i = 0; i < testimonialsSlider.slides.length; i++) {
        if (document.getElementsByClassName('activateOpacity').length && [i] !== index) {
            testimonialsSlider.slides[i].style.opacity = '0';
            testimonialsSlider.slides[index].style.opacity = '1';
            setTimeout(function() {
                testimonialsSlider.slides[index].style.transition = '.2s';
                testimonialsSlider.slides[i].style.transition = '.8s';
            }, 50);
        } else if ([i] < index) {
            testimonialsSlider.slides[i].style.opacity = '0';
            setTimeout(function() {
                testimonialsSlider.slides[i].style.transition = '.8s';
            }, 50);
        } else {
            testimonialsSlider.slides[i].style.opacity = "1";
            setTimeout(function() {
                testimonialsSlider.slides[i].style.transition = "0s";
            },50);
        }
    }
});


// instaFeed Swiper
function instafeedOnLoad() {
    (function () {
        // new InstagramFeed({
        //     'username': 'reesink_horses',
        //     'container': document.getElementById("instafeed"),
        //     'display_profile': false,
        //     'display_biography': false,
        //     'display_gallery': true,
        //     'callback': null,
        //     'get_data': false,
        //     'styling': true,
        //     'items': 4,
        //     'items_per_row': 2,
        //     'margin': 0,
        //     'image_size': 320,
        // });

        if (document.getElementById("instafeed") !== null) {
            fetch('https://ig.instant-tokens.com/users/a925176f-3d64-4c6c-8fe1-76a7ea1db2ac/instagram/17841411022007038/token?userSecret=1jkzmowgcs8gjl0j1wjj0q\n')
                .then((response) => {
                    return response.json();
                })
                .then((myJson) => {
                    const feed = new Instafeed({
                        filter: function(image) {
                            var MAX_LENGTH = 201;
                            if (image.caption && image.caption.text) {
                                image.short_caption = image.caption.text.slice(0, MAX_LENGTH);
                            } else {
                                image.short_caption = "";
                            }

                            return true;
                        },
                        template: `<div class="item w-1/2"><a href="{{link}}" target="_blank"><img class="instafeedImage" alt="{{caption}}" src="{{image}}"/></a></div>`,
                        limit: 4,
                        resolution: "standard_resolution",
                        accessToken: myJson.Token
                    });
                    feed.run();
                });
        }
    })();
}

if (window.attachEvent) {
    window.attachEvent('onload', instafeedOnLoad);
} else if (window.addEventListener) {
    window.addEventListener('load', instafeedOnLoad, false);
} else {
    document.addEventListener('load', instafeedOnLoad, false);
}



